import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import './widgetLanguage.scss';

import useLanguage, { languages } from '../hooks/useLanguage';


const LanguageSwitch = () => {
    const node = useRef();

    const { state: languageState, dispatch: dispatchLanguageChange } = useLanguage()
    
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const [currentLanguageImg, setCurrentLanguageImg] = useState('');
    const [currentLanguage, setCurrentLanguage] = useState('');

    const icons = useStaticQuery(graphql`
          {
            allFile(filter: { extension: { eq: "png" } }) {
              edges {
                node {
                  publicURL
                  name
                }
              }
            }
          }
    `)

    const imageSwitch = (l) => {
        switch(l) {
            case 'ru':
                return `/assets/ru.png`
            case 'zh':
                return `/assets/zh.png`
            case 'en': 
                return `/assets/en.png`
            default: 
                return `/assets/en.png`
        }
    }

    const changeLanguage = (lng) => {
        dispatchLanguageChange({type: 'CHANGE_LANGUAGE', payload: lng});
        setIsOpen(false);
    }

    
    const handleOutsideClick = event => {
        if (node.current && node.current.contains(event.target)) {
          return
        } else {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        setCurrentLanguageImg(imageSwitch(languageState.lng))
    }, [languageState.lng])

    useEffect(() => {
        setCurrentLanguage(languageState.lng)
    }, [languageState.lng]);

    useEffect(() => {
        if (isOpen) {
          document.addEventListener('mousedown', handleOutsideClick);
        } else {
          document.removeEventListener('mousedown', handleOutsideClick);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        }
    
    }, [isOpen]);

    return (
        <div ref={node} className={`languageSwitch__container`}>
            <button
                title={t("change language")}
                className='languageSwitch__container__toggleOpenBtn'
                onMouseDown={() => setIsOpen(isOpen ? false : true)}
            >
            {
                currentLanguageImg
                ?
                <img
                    src={currentLanguageImg}
                />
                :
                null
            }
            </button>
            <div
                className={`languageSwitch__container__buttonsContainer ${isOpen ? 'languageSwitch__container__buttonsContainer--open' : ''}`}
            >
                {
                    languages.map(l => (
                        <button
                            className='languageBtn'
                            key={l}
                            onClick={() => changeLanguage(l)}
                            disabled={currentLanguage === l}
                        >
                            <img
                                src={(icons.allFile.edges.find(n => n.node.name === l)).node.publicURL}
                                alt={l}
                            />
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export default LanguageSwitch;

