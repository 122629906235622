import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { themeContext } from '../../stateProvider';

import './widgetOnsiteNavigation.scss';
import { useState } from 'react';

const isPartiallyActiveLink = ({
    isPartiallyCurrent
  }) => {
    return isPartiallyCurrent
      ? { className: "onsiteNavigationDesktop__linksContainer__link onsiteNavigationDesktop__linksContainer__link--active" }
      : {}
}

const isPartiallyActiveSublink = ({
    isPartiallyCurrent
  }) => {
    return isPartiallyCurrent
      ? { className: "onsiteNavigationDesktop__linksContainer__blogDiv__link onsiteNavigationDesktop__linksContainer__blogDiv__link--active" }
      : {}
  }

  const isPartiallyActiveMobile = ({
    isPartiallyCurrent
  }) => {
    return isPartiallyCurrent
      ? { className: "onsiteNavigationMobile__linksContainer__link onsiteNavigationMobile__linksContainer__link--active" }
      : {}
  }

export const OnsiteNavigationMobile = ({ isOpen, handleOpen }) => {
    const { t, i18n } = useTranslation();
    const { state: themeState } = useContext(themeContext);

    return (
        <div
            className={`onsiteNavigationMobile onsiteNavigationMobile--${themeState.theme} ${isOpen ? 'onsiteNavigationMobile--open' : 'onsiteNavigationMobile--closed'}`}
        >
            <div
                className={`onsiteNavigationMobile__linksContainer`}
            >
                <Link 
                    getProps={isPartiallyActiveMobile}
                    className={`onsiteNavigationMobile__linksContainer__link`}
                    to="/portfolio/"
                    activeClassName={`onsiteNavigationMobile__linksContainer__link--active`}
                >
                    {t("widget.onsiteNavigation.portfolio")}
                </Link>
                <Link 
                    getProps={isPartiallyActiveMobile}
                    className={`onsiteNavigationMobile__linksContainer__link`}
                    to="/blog/"
                    activeClassName={`onsiteNavigationMobile__linksContainer__link--active`}
                >
                    {t("widget.onsiteNavigation.blog")}
                </Link>
                <Link 
                    getProps={isPartiallyActiveMobile}
                    className={`onsiteNavigationMobile__linksContainer__link`}
                    to="/about/"
                    activeClassName={`onsiteNavigationMobile__linksContainer__link--active`}
                >
                    {t("widget.onsiteNavigation.aboutMe")}
                </Link>
            </div>
        </div>
    )
}


export const OnsiteNavigationDesktop = () => {
    let location = useLocation();
    const [isBlogDivOpen, setIsBlogDivOpen] = useState(location && location.pathname.includes('blog') ? true : false);
    const { t, i18n } = useTranslation();
    const { state: themeState } = useContext(themeContext);

    return (
        <div
            className={`onsiteNavigationDesktop onsiteNavigationDesktop--${themeState.theme}`}
        >
            <div
                className={`onsiteNavigationDesktop__linksContainer`}
            >
                <Link 
                    getProps={isPartiallyActiveLink}
                    className={`onsiteNavigationDesktop__linksContainer__link`}
                    to="/portfolio/"
                    activeClassName={`onsiteNavigationDesktop__linksContainer__link--active`}
                >
                    {t("widget.onsiteNavigation.portfolio")}
                </Link>
                <>
                <Link 
                    getProps={isPartiallyActiveLink}
                    className={`onsiteNavigationDesktop__linksContainer__link`}
                    to="/blog/"
                    activeClassName={`onsiteNavigationDesktop__linksContainer__link--active`}
                    style={{marginBottom: `${isBlogDivOpen ? '0' : '1em'}`}}
                >
                    {t("widget.onsiteNavigation.blog")}
                </Link>
                <button
                    className={`onsiteNavigationDesktop__linksContainer__blogOpenBtn ${isBlogDivOpen ? 'onsiteNavigationDesktop__linksContainer__blogOpenBtn--active' : ''}`}
                    onClick={() => setIsBlogDivOpen(isBlogDivOpen ? false : true)}
                >
                    <svg 
                        width="1em" 
                        height="1em" 
                        viewBox="0 0 16 16" 
                        className={`onsiteNavigationDesktop__linksContainer__blogOpenBtn__svg`}
                        fill="currentColor" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path 
                        fillRule="evenodd" 
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                    </svg>
                </button>
                </>
                <div
                    className={`onsiteNavigationDesktop__linksContainer__blogDiv ${isBlogDivOpen ? 'onsiteNavigationDesktop__linksContainer__blogDiv--open' : ''}`}
                >
                    <Link 
                        getProps={isPartiallyActiveSublink}
                        className={`onsiteNavigationDesktop__linksContainer__blogDiv__link`}
                        to="/blog/onclick-tutorials/"
                        activeClassName={`onsiteNavigationDesktop__linksContainer__blogDiv__link--active`}
                    >
                        onClick!
                    </Link>
                    <Link 
                        getProps={isPartiallyActiveSublink}
                        className={`onsiteNavigationDesktop__linksContainer__blogDiv__link`}
                        to="/blog/tech-and-beyond/"
                        activeClassName={`onsiteNavigationDesktop__linksContainer__blogDiv__link--active`}
                    >
                        Tech &amp; beyond
                    </Link>
                </div>
                <Link 
                    getProps={isPartiallyActiveLink}
                    className={`onsiteNavigationDesktop__linksContainer__link`}
                    to="/about/"
                    activeClassName={`onsiteNavigationDesktop__linksContainer__link--active`}
                >
                    {t("widget.onsiteNavigation.aboutMe")}
                </Link>
            </div>
        </div>
    )
}