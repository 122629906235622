import React, { useContext, useState, useRef, useEffect } from 'react';

import HomepageLink from './homepageLink';
import LanguageSwitch from './widgetLanguage';
import { ColormodeSwitch, ColormodeSwitchMobile } from './widgetColormode';

import './navbar.scss';
import { themeContext } from '../../stateProvider';
import LanguageSwitchMobile from './widgetLanguageMobile';
import { OnsiteNavigationMobile } from './widgetOnsiteNavigation';

const Navbar = ({ siteTitle }) => {
    const { state: themeState } = useContext(themeContext);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const node = useRef();

    let handleToggleMenuOpen = () => {
        setIsMenuOpen(true)
    }

    const handleOutsideClick = event => {
        if (node.current && node.current.contains(event.target)) {
          return
        } else {
            setIsMenuOpen(false)
        }
      }
    
    useEffect(() => {
        if (isMenuOpen) {
          document.addEventListener('mousedown', handleOutsideClick);
        } else {
          document.removeEventListener('mousedown', handleOutsideClick);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        }
    
    }, [isMenuOpen]);

    useEffect(() => {
        if (!isMenuOpen) {
            handleToggleMenuOpen = () => {
                setIsMenuOpen(true)
            }
        } else {
            handleToggleMenuOpen = () => {
                setIsMenuOpen(false)
            }
        }
    }, [isMenuOpen])

    return (
        <div className={`navbar ${themeState.theme}`}>
            <HomepageLink />
            <button 
                className={`navbar__mobileMenuBtn ${isMenuOpen ? 'navbar__mobileMenuBtn--open' : 'navbar__mobileMenuBtn--closed'}`}
                onMouseDown={() => handleToggleMenuOpen()}    
            >
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div 
                className={`navbar__mobileMenu ${isMenuOpen ? 'navbar__mobileMenu--open' : 'navbar__mobileMenu--closed'}`}
                ref={node}
            >
                <LanguageSwitchMobile/>
                <ColormodeSwitchMobile />
                <OnsiteNavigationMobile
                    isOpen={isMenuOpen}
                    handleOpen={() => handleToggleMenuOpen()}
                />
            </div>
            <LanguageSwitch/>
            <ColormodeSwitch/>
        </div>
    )
}

export default Navbar;

