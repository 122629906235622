import React, { useReducer, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';

export const languages = [
    'en',
    'ru',
    'zh'
]
const defaultLang = 'en';
const pagesWithNotPrefix = [
    '/',
    '/about',
    '/about/',
    '/portfolio',
    '/portfolio/',
    '/cookies',
    '/cookies/',
    '/privacy',
    '/privacy/',
    '/blog',
    '/blog/',
    '/blog/tech-and-beyond',
    '/blog/tech-and-beyond/',
    '/blog/onclick-tutorials',
    '/blog/onclick-tutorials/'
];
const checkOldLocation = (oldLocation) => {
    let result = true;
    for (let i = 0; i < pagesWithNotPrefix.length; i++) {
        if (oldLocation === pagesWithNotPrefix[i]) {
            result = false;
            return result;
        }
    }
    return result;
}

const languageReducer = (state, action) => {
    switch(action.type) {
        case 'CHANGE_LANGUAGE': 
            return {...state, lng: action.payload}
        default:
            return state;
    }
}

export default function useLanguage () {
    let location = useLocation();
    const { t, i18n } = useTranslation();
    const [state, dispatch] = useReducer(languageReducer, {
        lng: i18n.language.substr(0, 2)
    });

    useEffect(() => {
        const changeLanguage = (lng) => {
            let oldLang = i18n.language;
            let oldLocation = location.pathname;
            if (checkOldLocation(oldLocation)) {
                if (lng !== defaultLang && oldLang !== defaultLang) {
                    let newLocation = oldLocation.replace(`/${oldLang}/`, `/${lng}/`)
                    navigate(newLocation);
                } else if (lng !== defaultLang && oldLang === defaultLang) {
                    let postPath = oldLocation.slice(oldLocation.lastIndexOf('/'));
                    let rootPath = oldLocation.slice(0, oldLocation.indexOf(postPath));
                    let newLocation = `${rootPath}/${lng}${postPath}`
                    navigate(newLocation);
                } else if (lng === defaultLang && oldLang !== defaultLang) {
                    let newLocation = oldLocation.replace(`/${oldLang}/`, '/');
                    navigate(newLocation);
                }
            }
            i18n.changeLanguage(lng);
        }

        changeLanguage(state.lng);
    }, [state]);

    return {state, dispatch};
}