import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import './widgetLanguageMobile.scss';

import useLanguage, { languages } from '../hooks/useLanguage';


const LanguageSwitchMobile = () => {
    const { state: languageState, dispatch: dispatchLanguageChange } = useLanguage()
    const { t, i18n } = useTranslation();

    const [currentLanguage, setCurrentLanguage] = useState('');

    const icons = useStaticQuery(graphql`
          {
            allFile(filter: { extension: { eq: "png" } }) {
              edges {
                node {
                  publicURL
                  name
                }
              }
            }
          }
    `)

    const changeLanguage = (lng) => {
        dispatchLanguageChange({type: 'CHANGE_LANGUAGE', payload: lng});
    }

    useEffect(() => {
        setCurrentLanguage(languageState.lng)
    }, [languageState.lng]);

    return (
        <div className={`languageSwitchMobile__container`}>
            <div
                className={`languageSwitchMobile__container__buttonsContainer`}
            >
                {
                    languages.map(l => (
                        <button
                            className='languageBtnMobile'
                            key={l}
                            onClick={() => changeLanguage(l)}
                            disabled={currentLanguage === l}
                        >
                            <img
                                src={(icons.allFile.edges.find(n => n.node.name === l)).node.publicURL}
                                alt={l}
                            />
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export default LanguageSwitchMobile;