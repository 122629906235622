import React from 'react';
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';

import './widgetCookieBanner.scss';

const CookieBanner = ({ handleAgree }) => {
    const { t, i18n } = useTranslation()

    return (
        <div className={`cookieBannerDiv`}>
            <div className="cookieBannerDiv__message">
                <span>
                    {t("widget.cookieBanner.messageSpan1")}
                </span>
                <Link
                    to="/cookies/"
                >
                    {t("widget.cookieBanner.cookiesPolicy")}
                </Link>
                <span>
                    {t("widget.cookieBanner.messageSpan2")}
                </span>
                <Link
                    to="/privacy/"
                >
                    {t("widget.cookieBanner.privacyPolicy")}
                </Link>
            </div>
            <button
                className="cookieBannerDiv__button"
                onClick={() => handleAgree()}
            >
                {t("widget.cookieBanner.okBtn")}
            </button>
        </div>
    )
}

export default CookieBanner;