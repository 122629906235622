import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import { cookiesContext, themeContext } from "../../stateProvider";

// import Header from "./header"
import Navbar from './navbar';

import "./layout.css";
import '../styles/global.scss';
import { OnsiteNavigationDesktop } from "./widgetOnsiteNavigation";
import CookieBanner from "./widgetCookieBanner";
import { TwitterIcon } from "react-share";

const Layout = ({ children }) => {
  const cookieShown = true;
  const { state: themeState } = useContext(themeContext);

  const { state: cookiesState, dispatch: cookiesDispatch } = useContext(cookiesContext);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar siteTitle={data.site.siteMetadata.title} />
      <OnsiteNavigationDesktop />
      <div
        className='container'
      >
        <main className={`main ${themeState.theme}`}>{children}</main>
        <footer className={`footer footer--${themeState.theme}`}>
            <div>
            <div style={{fontSize: 'smaller', marginTop: '1em'}}>
              © {new Date().getFullYear()} Dmitry Zhukov. All Rights Reserved
            </div>
            <div className='footer__linksDiv'>
            <Link
              to="/cookies/"
            >
              Cookies Policy
            </Link>
            <Link
              to="/privacy/"
            >
              Privacy policy
            </Link>
            </div>
            <a
              target="blank"
              href="https://twitter.com/DmitryJima"
              className="footer__twitterLink"
            >
              <TwitterIcon  size={27} round={true} />
            </a>
            <a
              target="blank"
              href="https://github.com/dmitryjima"
              className="footer__githubLink" 
            >
              <svg className="footer__githubLink__svg" width="1.5em" height="1.5em" viewBox="0 0 438.549 438.549" 
               xmlns="http://www.w3.org/2000/svg"
              >
	<path fill="var(--mainColorBlue--thin)" d="M409.132,114.573c-19.608-33.596-46.205-60.194-79.798-79.8C295.736,15.166,259.057,5.365,219.271,5.365
		c-39.781,0-76.472,9.804-110.063,29.408c-33.596,19.605-60.192,46.204-79.8,79.8C9.803,148.168,0,184.854,0,224.63
		c0,47.78,13.94,90.745,41.827,128.906c27.884,38.164,63.906,64.572,108.063,79.227c5.14,0.954,8.945,0.283,11.419-1.996
		c2.475-2.282,3.711-5.14,3.711-8.562c0-0.571-0.049-5.708-0.144-15.417c-0.098-9.709-0.144-18.179-0.144-25.406l-6.567,1.136
		c-4.187,0.767-9.469,1.092-15.846,1c-6.374-0.089-12.991-0.757-19.842-1.999c-6.854-1.231-13.229-4.086-19.13-8.559
		c-5.898-4.473-10.085-10.328-12.56-17.556l-2.855-6.57c-1.903-4.374-4.899-9.233-8.992-14.559
		c-4.093-5.331-8.232-8.945-12.419-10.848l-1.999-1.431c-1.332-0.951-2.568-2.098-3.711-3.429c-1.142-1.331-1.997-2.663-2.568-3.997
		c-0.572-1.335-0.098-2.43,1.427-3.289c1.525-0.859,4.281-1.276,8.28-1.276l5.708,0.853c3.807,0.763,8.516,3.042,14.133,6.851
		c5.614,3.806,10.229,8.754,13.846,14.842c4.38,7.806,9.657,13.754,15.846,17.847c6.184,4.093,12.419,6.136,18.699,6.136
		c6.28,0,11.704-0.476,16.274-1.423c4.565-0.952,8.848-2.383,12.847-4.285c1.713-12.758,6.377-22.559,13.988-29.41
		c-10.848-1.14-20.601-2.857-29.264-5.14c-8.658-2.286-17.605-5.996-26.835-11.14c-9.235-5.137-16.896-11.516-22.985-19.126
		c-6.09-7.614-11.088-17.61-14.987-29.979c-3.901-12.374-5.852-26.648-5.852-42.826c0-23.035,7.52-42.637,22.557-58.817
		c-7.044-17.318-6.379-36.732,1.997-58.24c5.52-1.715,13.706-0.428,24.554,3.853c10.85,4.283,18.794,7.952,23.84,10.994
		c5.046,3.041,9.089,5.618,12.135,7.708c17.705-4.947,35.976-7.421,54.818-7.421s37.117,2.474,54.823,7.421l10.849-6.849
		c7.419-4.57,16.18-8.758,26.262-12.565c10.088-3.805,17.802-4.853,23.134-3.138c8.562,21.509,9.325,40.922,2.279,58.24
		c15.036,16.18,22.559,35.787,22.559,58.817c0,16.178-1.958,30.497-5.853,42.966c-3.9,12.471-8.941,22.457-15.125,29.979
		c-6.191,7.521-13.901,13.85-23.131,18.986c-9.232,5.14-18.182,8.85-26.84,11.136c-8.662,2.286-18.415,4.004-29.263,5.146
		c9.894,8.562,14.842,22.077,14.842,40.539v60.237c0,3.422,1.19,6.279,3.572,8.562c2.379,2.279,6.136,2.95,11.276,1.995
		c44.163-14.653,80.185-41.062,108.068-79.226c27.88-38.161,41.825-81.126,41.825-128.906
		C438.536,184.851,428.728,148.168,409.132,114.573z"/>
              </svg>
            </a>
            <a
              target="blank"
              href="/rss.xml"
              className="footer__rssLink" 
            >
              <svg className="footer__rssLink__svg" width="1.5em" height="1.5em" viewBox="0 0 16 16" 
              fill="var(--mainColorBlue--thin)" xmlns="http://www.w3.org/2000/svg"
              >
                <path fillRule="evenodd" d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm1.5 2.5a1 1 0 0 0 0 2 8 8 0 0 1 8 8 1 1 0 1 0 2 0c0-5.523-4.477-10-10-10zm0 4a1 1 0 0 0 0 2 4 4 0 0 1 4 4 1 1 0 1 0 2 0 6 6 0 0 0-6-6zm.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
              </svg>
            </a>
            </div>
            {
            !cookiesState.hasAgreed
            ?
            <CookieBanner
              handleAgree={() => cookiesDispatch({type: "AGREE_TO_COOKIES", payload: true })}
            />
            :
            null
            }
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
